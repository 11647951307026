import chart from "../assets/images/chart.png"
import {memo} from 'react';

function Tokenomics(){

    return(

        <>
        
            <img src={chart}/>

        </>

    )

}

export default memo(Tokenomics)