import { Row, Col } from "react-bootstrap";
import team from "../assets/images/team.png";
import lady from "../assets/images/lady.png";
import { memo } from "react";
import { useTranslation } from "react-i18next";
function Team() {
  const { t } = useTranslation();
  return (
    <>
      <Row className="gx-5">
        <Col lg={4}>
          <h2 className="space-h2">{t("team_heading")}</h2>
          <p className="space-p">{t("team_para_oluary")}</p>
        </Col>

        <Col lg={4} md={6}>
          <ul className="team-ul">
            <li>
              <img src={team} />

              <span>
                <h4>J. Antonio</h4>
                <p>Project Director</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Andres Casielles</h4>
                <p>Analyst - Data and Insights</p>
              </span>
            </li>

            <li>
              <img src={lady} />
              <span>
                <h4>Yadira Deusto</h4>
                <p>Venture Development Manager</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Edu Hernandes</h4>
                <p> Brand Marketing Intern </p>
              </span>
            </li>

            <li>
              <img src={lady} />
              <span>
                <h4>Martha Galindo</h4>
                <p>Real Estate Acquisition Manager</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Arsalan Shahzad</h4>
                <p>Chief Technical Officer</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Rameez Saleem</h4>
                <p>Project Manager</p>
              </span>
            </li>
          </ul>
        </Col>

        <Col lg={4} md={6}>
          <ul className="team-ul mt-res-0">
            <li>
              <img src={team} />

              <span>
                <h4>Tony Casielles</h4>
                <p>Operations Manager</p>
              </span>
            </li>

            <li>
              <img src={lady} />
              <span>
                <h4>Paulina Lujan</h4>
                <p>Business Intelligence Analyst</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Ivan Bentacourt</h4>
                <p>Architect</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Sergio Escobedo</h4>
                <p>Project Maintenance Officer</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Gulshan Goya</h4>
                <p>QA Engineering Analyst</p>
              </span>
            </li>

            <li>
              <img src={team} />
              <span>
                <h4>Fahad Wahid</h4>
                <p>Operational manager</p>
              </span>
            </li>
          </ul>
        </Col>
      </Row>
    </>
  );
}

export default memo(Team);
