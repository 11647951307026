import {
  Faq,
  Footer,
  Header,
  Team,
  Title,
  Tokenomics,
  Testimonial,
  Newsletter,
  Blog,
} from "../component";
import Slider from "react-slick";
import { Container, Row, Col } from "react-bootstrap";
import about from "../assets/images/about.png";
import SliderTwo from "../assets/images/slider2.jpg";
import SliderThree from "../assets/images/slider3.jpg";
import { useTranslation } from "react-i18next";
import ReactGA from 'react-ga'

function Home() {
      /*TODO:: GOOGLE ANALYTICS CODE START*/
    ReactGA.pageview(window.location.pathname + window.location.search);
    /*GOOGLE ANALYTICS CODE END*/
  const { t } = useTranslation();
  const settings = {
    dots: true,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <>
      <Header />

      <section className="slider-section">
        <Slider {...settings}>
          {/* <div className="slider-item1">
               
            </div> */}

          <div className="slider-item">
            <img src={SliderTwo} />
          </div>

          <div className="slider-item">
            <img src={SliderThree} />
          </div>
        </Slider>
      </section>

      <section className="section bg-section" id="about">
        <Container>
          <Title title={t("about_us")} />

          <Row className="about align-items-center">
            <Col lg={6}>
              <div className="about-meta">
                <h2 className="space-h2">{t("about_us_heading")}</h2>
                <p className="space-p">{t("about_us_para_oluary")}</p>
              </div>
            </Col>

            <Col lg={6} className="about-img">
              <img src={about} />
            </Col>
          </Row>
        </Container>
      </section>

      <section className="section token-section" id="tokenomics">
        <Container>
          <Title title={t("tokenomics")} class="text-white" />

          <Tokenomics />
        </Container>
      </section>

      <section className="team-section section" id="team">
        <Container>
          <Title title={t("team")} />

          <Team />
        </Container>
      </section>
      <section className="section testimonial-section" id="testimonial">
        <Container>
          <Title title={t("client_reviews")} />

          <Testimonial />
        </Container>
      </section>
      <section className="section bg-section blog-section" id="blog">
        <Container>
          <Title title={t("our_blog")} />
          <Blog />
        </Container>
      </section>
      <section className="section News-section" id="News">
        <Title
        //             title="Subscribe To Get The Latest
        // News About Us"
        />
        <Newsletter />
      </section>

      <section className="section bg-section" id="faq">
        <Container>
          <Title title={t("faqs_heading")} />

          <Faq />
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default Home;
