import React ,{useState} from "react";
import { useTranslation } from "react-i18next";
import emailjs from '@emailjs/browser';

function Newsletter() {

  const { t } = useTranslation();
  const [email, setEmail] = useState('');
  const [loader, setLoader] = useState(false);

  const onSubmitHandler = async (e) => {
    e.preventDefault();
    setLoader(true);
    emailjs.init('UyWlw7gHtIOd_cFx7');
    await emailjs.send('service_dmxy6ev','template_c1ia6fg', {
        from_name: "Oluary",
        from_email: email,
    }, 'UyWlw7gHtIOd_cFx7')
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);
          alert('Thank you for subscribe')
            setEmail('');
            setLoader(false);
        }, (err) => {
          console.log('FAILED...', err);
          setLoader(false);
        });
  }
  return (
    <div className="news ">
      <div className="container">
        <h1 className="news-heading">
          {t("news_letter_heading")}
          <br /> {t("about_us")}
        </h1>
        <p className="des how-de">{t("news_letter_para")}</p>

        <form onSubmit={onSubmitHandler} dir="ltr">
          <input
            type="email"
            maxLength="50"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email address"
          />
          <button className="bt" type="submit" disabled={loader}>{ loader ? "Subscribing" : t("subscribe_btn")}</button>
        </form>
      </div>
    </div>
  );
}

export default Newsletter;
