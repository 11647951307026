import ReactGA from "react-ga";

const eventTrack = (action = 'action', label = 'label', category = 'Custom Event') => {
    // console.log("GA event:", category, ":", action, ":", label);
    ReactGA.event({
        category: category,
        action: action,
        label: label,
    })
}

export default eventTrack;