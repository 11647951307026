import { Footer, Header, Title } from "../component";
import { Container, Row, Col, Form } from "react-bootstrap";
import { memo, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import axios from "axios";
import ReactGA from "react-ga";
import eventTrack from "../utils/eventTrack";
import apis from "../services";


function Kyc() {
  /*TODO:: GOOGLE ANALYTICS CODE START*/
  ReactGA.pageview(window.location.pathname + window.location.search);
  /*GOOGLE ANALYTICS CODE END*/
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [phone, setPhone] = useState("");
  const [dob, setDob] = useState("");
  const [passport, setPassport] = useState();
  const [nicFront, setNicFront] = useState();
  const [nicBack, setNicBack] = useState();
  const [residential, setResidential] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setlastNameError] = useState("");
  const [dobError, setDobError] = useState("");
  const [nicFrontError, setNicFrontError] = useState("");
  const [nicBackError, setNicBackError] = useState("");
  const [passportError, setPassportError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [residentialError, setResidentialError] = useState("");

  const [passValue, setPassValue] = useState();
  const [frontValue, setFrontValue] = useState();
  const [backValue, setBackValue] = useState();

  const [message, setMessage] = useState("");
  const [test, setTest] = useState("");
  const [flag, setFlag] = useState(false);

  const [loader, setLoader] = useState(false);

  const submitKycData = async (e) => {
    e.preventDefault();
    eventTrack('KYC Form', 'Submission of KYC Form')
    setMessage("");
    try {
      firstName.trim() == "" && setFirstNameError("Please enter First Name.");
      lastName.trim() == "" && setlastNameError("Please enter Last Name.");
      dob.trim() == "" && setDobError("Please enter your DOB.");
      phone.trim() == "" && setPhoneError("Please enter phone Number.");
      passport == null && setPassportError("Please enter Passport.");
      nicFront == null && setNicFrontError("Please enter CNIC Front.");
      nicBack == null && setNicBackError("Please enter CNIC Back.");
      residential.trim() == "" &&
        setResidentialError("Please enter Residential.");


      await hitSubmitApi();
    } catch (error) {
      console.log(error);
    }
  };

  const hitSubmitApi = async () => {
    setTest("sdfsfd");

    try {
      if (
        firstName.trim() &&
        lastName.trim() &&
        dob.trim() &&
        phone.trim() &&
        passport != null &&
        nicFront != null &&
        residential.trim() &&
        nicBack != null
      ) {

        const data = {
          firstname: firstName,
          lastname: lastName,
          middlename: middleName,
          phone: phone,
          dob: dob,
          passport: passport,
          nic_front: nicFront,
          nic_back: nicBack,
          residential: residential,
        };

        setLoader(true);
        const res = await apis.submitKycData(data);

        setLastName("");
        setFirstName("");
        setMiddleName("");
        setPhone("");
        setDob("");
        setPassport(null);
        setNicBack(null);
        setNicFront(null);
        passValue.value = "";
        frontValue.value = "";
        backValue.value = "";

        setResidential("");
        setFlag(true);
        res != null && console.log(res.data.message);
        res != null && setMessage(res.data.message);

        setLoader(false);
      } else {
        console.log("validation is incorrect");
      }
    } catch (error) {
      setLoader(false);
      if (
        error != undefined &&
        error.response.data.message == "Kyc is already created"
      )
        setPhoneError("Kyc is already created on this phone");
    }
  };

  const setImage = (e) => {
    const { name, files } = e.target;
    const file = files[0];
    let ext = file.name.split(".").pop();
    if (ext === "jpg" || ext === "jpeg" || ext === "png") {
      if (name === "passport") {
        setPassport(file);
        setPassValue(e.target);
        setPassportError("");
      }
      if (name === "nic_front") {
        setNicFront(file);
        setFrontValue(e.target);
        setNicFrontError("");
      }
      if (name === "nic_back") {
        setNicBack(file);
        setBackValue(e.target);
        setNicBackError("");
      }
    } else {
      if (name == "passport") {
        setPassport("");
        setPassportError("Please enter valid format image.");
      }
      if (name == "nic_front") {
        setNicFront("");
        setNicFrontError("Please enter valid format image.");
      }
      if (name == "nic_back") {
        setNicBackError("Please enter valid format image.");
        setNicBack("");
      }
      alert("please select image with formats, jpg, png, jpeg!");
    }
  };

  return (
    <>
      <Header type="dark" />

      <section className="section bg-section">
        <Container>
          <Title title="KYC" />

          <Row className="about align-items-center">
            <Col lg={6}>
              <div className="about-meta">
                <h2 className="space-h2">{t("kyc_heading")}</h2>
                <p className="space-p " style={{ marginRight: "40px" }}>
                  {t("kyc_para_jtc")}
                </p>
              </div>
            </Col>

            <Col lg={6}>
              <Form>
                <Row>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[
                        lastNameError || firstNameError ? "mb-0" : "mb-3",
                      ]}`}
                      controlId="firstname"
                    >
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="First Name"
                        value={firstName}
                        onChange={(e) => {
                          firstNameError && setFirstNameError("");
                          setFirstName(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {firstNameError ? (
                      <span style={{ color: "red" }}> {firstNameError} </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[
                        lastNameError || firstNameError ? "mb-0" : "mb-3",
                      ]}`}
                      controlId="lastname"
                    >
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Last Name"
                        value={lastName}
                        onChange={(e) => {
                          lastNameError && setlastNameError("");
                          setLastName(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {lastNameError ? (
                      <span style={{ color: "red" }}> {lastNameError} </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[phoneError ? "mb-0" : "mb-3"]}`}
                      controlId="middlename"
                    >
                      <Form.Label>Middle Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Middle Name"
                        value={middleName}
                        onChange={(e) => setMiddleName(e.target.value)}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[phoneError ? "mb-0" : "mb-3"]}`}
                      controlId="phone"
                    >
                      <Form.Label>Phone</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Phone"
                        value={phone}
                        onChange={(e) => {
                          phoneError && setPhoneError("");
                          setPhone(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {phoneError ? (
                      <span style={{ color: "red" }}> {phoneError} </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[
                        passportError || dobError ? "mb-0" : "mb-3",
                      ]}`}
                      controlId="dob"
                    >
                      <Form.Label>DOB</Form.Label>
                      <Form.Control
                        type="date"
                        value={dob}
                        onChange={(e) => {
                          dobError && setDobError("");
                          setDob(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {dobError ? (
                      <span style={{ color: "red" }}> {dobError} </span>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[passportError ? "mb-0" : "mb-3"]}`}
                      controlId="passport"
                    >
                      <Form.Label>Passport</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="passport"
                        onChange={setImage}
                      />
                    </Form.Group>
                    {passportError ? (
                      <span style={{ color: "red" }}>{passportError} </span>
                    ) : (
                      " "
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[
                        nicBackError || nicFrontError ? "mb-0" : "mb-3",
                      ]}`}
                      controlId="cnicfront"
                    >
                      <Form.Label>CNIC Front</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="nic_front"
                        onChange={setImage}
                      />
                    </Form.Group>
                    {nicFrontError ? (
                      <p style={{ color: "red" }}>{nicFrontError} </p>
                    ) : (
                      " "
                    )}
                  </Col>
                  <Col lg={6}>
                    <Form.Group
                      className={`${[
                        nicBackError || nicFrontError ? "mb-0" : "mb-3",
                      ]}`}
                      controlId="cnicback"
                    >
                      <Form.Label>CNIC Back</Form.Label>
                      <Form.Control
                        type="file"
                        accept="image/*"
                        name="nic_back"
                        onChange={setImage}
                      />
                    </Form.Group>
                    {nicBackError ? (
                      <p style={{ color: "red" }}>{nicBackError} </p>
                    ) : (
                      " "
                    )}
                  </Col>
                  <Col lg={12}>
                    <Form.Group
                      className={`${[residentialError ? "mb-0" : "mb-3"]}`}
                      controlId="resedential"
                    >
                      <Form.Label>Residential</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={residential}
                        onChange={(e) => {
                          residentialError && setResidentialError();
                          setResidential(e.target.value);
                        }}
                      />
                    </Form.Group>
                    {residentialError ? (
                      <p className="mb-3" style={{ color: "red" }}>
                        {residentialError}{" "}
                      </p>
                    ) : (
                      " "
                    )}

                    {message ? (
                      <p
                        className="mb-3"
                        style={{ color: "green", textAlign: "center" }}
                      >
                        {message}
                      </p>
                    ) : (
                      ""
                    )}
                  </Col>

                  <Col lg={12}>
                    <button
                      disabled={loader}
                      className="custom-btn primary-btn"
                      onClick={submitKycData}
                    >
                      {loader ? 'Submitting' : 'Submit' }
                    </button>
                  </Col>
                </Row>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>

      <Footer />
    </>
  );
}

export default memo(Kyc);
