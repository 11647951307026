import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import logo from "../assets/images/favicon-wh.png";
import { memo } from "react";
import { useTranslation } from "react-i18next";
import eventTrack from "../utils/eventTrack";

function Footer() {
  const { t } = useTranslation();
  return (
    <>
      <footer>
        <Container className="footer-top">
          <Row>
            <Col lg={4} md={4}>
              <Link
                to={"/"}
                className="logo"
                onClick={() => eventTrack("Footer Logo", "/")}
              >
                {/* <h2>JTC</h2> */}
                <img src={logo} />
              </Link>

              <p className="footer-about"> {t("footer_para")} </p>
            </Col>

            <Col lg={4} md={4}>
              <h4>{t("footer_links_heading")}</h4>

              <ul className="footer-menu">
                <li>
                  <a
                    href="#about"
                    onClick={() =>
                      eventTrack("About us", "About information on Footer")
                    }
                  >
                    {t("about_us")}
                  </a>
                </li>

                <li>
                  <a
                    href="#tokenomics"
                    onClick={() =>
                      eventTrack("Tokenomics", "Tokenomics Graph on Footer")
                    }
                  >
                    {t("tokenomics")}
                  </a>
                </li>

                <li>
                  <a
                    href="#team"
                    onClick={() =>
                      eventTrack("Team", "Meet our Team information on Footer")
                    }
                  >
                    {t("team")}
                  </a>
                </li>

                {/* <li>
                            <a href="#faq">FAQ</a>

                            </li> */}
              </ul>
            </Col>

            <Col lg={4} md={4}>
              <h4>{t("footer_icons_heading")}</h4>

              <ul className="social bottom">
                <li>
                  <a
                    href=""
                    onClick={() =>
                      eventTrack("Twitter", "Social Link in Footer")
                    }
                  >
                    <i className="fa-brands fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() =>
                      eventTrack("Instagram", "Social Link in Footer")
                    }
                  >
                    <i className="fa-brands fa-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href=""
                    onClick={() =>
                      eventTrack("Discord", "Social Link in Footer")
                    }
                  >
                    <i className="fa-brands fa-discord"></i>
                  </a>
                </li>
              </ul>
            </Col>
          </Row>
        </Container>

        <Container className="footer-bottom">
          <p>
            {" "}
            Copyright &copy; {new Date().getFullYear()} Oluary All Rights
            Reserved
          </p>
        </Container>
      </footer>
    </>
  );
}

export default memo(Footer);
